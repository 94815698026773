.content-section {
    max-width: 90%;
}

.content-section--title {
    text-align: left;
}

.content-section--body {
    text-align: justify;


}