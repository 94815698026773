header {
    display: flex;
    flex-direction: row;
    max-height: 150px;
    align-content: center;
    align-self: center;
    border-bottom: 2px solid #e5e5e5;
    top: 0;
    position: sticky;
    background: white;
}

.header--logo {
    max-width: 150px;
}

.header--title {
    align-self: center;
    font-family: 'Urbanist', sans-serif;
    font-size: 2rem;
}