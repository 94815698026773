.body {
    font-family: 'Urbanist', sans-serif;
}

.body--title {
    max-width: 60%;
    font-size: 4rem;
    text-align: left;
    padding-left: 15%;
    offset-top: 5%;
    offset-bottom: 5%;
}

.body--main-content {
    width: 90%;
    padding-left: 10%;
}

.body--main-content {
    display: flex;
    flex-direction: row;
}

.body--content {

}